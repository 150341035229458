import Image from 'next/image'

type Props = {
  mobileBannerBgUrl: string
  mobileBannerUrl: string
  alt: string
}

const Header = (props: Props) => {
  const { mobileBannerBgUrl, mobileBannerUrl, alt } = props
  return (
    <header
      className='mobileUp:hidden block bg-cover'
      style={{ backgroundImage: `url(${mobileBannerBgUrl})` }}
    >
      <Image
        src={mobileBannerUrl}
        alt={alt}
        width={414}
        height={606}
        style={{ width: '100%', height: 'auto' }}
      />
    </header>
  )
}

export default Header
